import * as React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import Seo from "../components/seo"

import '../styles/Biography.css'

const Biography = ({ location }) => {
    return (
        <>
          <Layout location={location} >
          <Helmet>
            <meta property="og:image" content="https://i.imgur.com/gjAS427.png" />
            <meta name="twitter:image" content="https://i.imgur.com/gjAS427.png" />
          </Helmet>
            <Seo 
              title="Biography"
              description="Know how is JL Caraveo in some minutes."
            />
            <main className="biography--container">
              <section className="biography-content--container">
                <article className="biography-content--desc">
                    <h2 className='how-is'>How is JL Caraveo?</h2>
                    <h3>We present you who is JL Caraveo in 5 minutes.</h3>
                    <ul>
                        <li>His full name is José Luis Higuera Caraveo. He is from Mexico. Felling very proud to born in the most beautiful country of the world. He was born in September 16th 1992. Yes, now you can calculate how old He is. </li>
                        <li>José went to elementary school from 1999 to 2005.</li>
                        <li>From 2005 to 2011, he went to high school.</li>
                        <li>On 2011, he had to make one of the hard decisions of his life, chose the carrier that he would work for the rest of his life. He really did not know exactly what he wanted, fortunately his passion about math helps him to make the decision. José decided to chose an engineering, Industrial Engineering to be exact. He went to the university from 2011 to 2015. During this stage, he realized that the statistics are beautiful, since the beginning, they become on his favorite subjects. </li>
                        <li>From 2016 to 2021 he worked on Hyundai Translead, in the product engineering department. His activities were related to make mechanical drawings of the product’s parts, make assembly list of the project and apply engineering change requests. At the third year of experience, He was promoted as a team leader, now with his own projects and his own team to work on them.</li>
                        <li>Was on 2020 when, during the pandemic, knew about Data Science, how the statistic plays an important role on this discipline. Since there, he has learned the most important techniques to become a Data Scientist and Machine Learning Engineer and he has worked in several projects, the most important are available on https://www.jlcaraveo.com/projects.</li>
                    </ul>
                    <h3>JL Caraveo on these days.</h3>
                    <ul>
                      <li>Learning Data Science has changed his life, his way of thinking, the way problems are approached, working with data has been very exciting, finding patterns, trends, applying techniques with incredible results, among many other points that he has experienced throughout of learning.</li>
                      <li>His path does not end here, his learning is continuous, learning new techniques, technologies, developing new projects, are some of the objectives he has in the present and for the future. But the greatest of them is applying everything learned in a company with social responsibility, working as a team to develop solutions that help improve people's lives, always focused on being friendly and responsible with the environment.</li>
                    </ul>
                </article>
              </section>
            </main>
          </Layout>
        </>
    )
}

export default Biography

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`